import React from "react";

export function Terms() {
  return (
    <div className={"content"}>
      <p>
        Les présentes conditions générales de vente (« CGV ») s’appliquent aux
        conventions conclues chez les partenaires Esthima entre : <br />
        - La société INCINERIS (le « Prestataire ») société par actions
        simplifée à associé unique, immatriculée au Registre du Commerce et des
        Sociétés de Lille Métropole sous le n°805 018 959 et dont le capital
        social s’élève à 5 500 000 €. Le siège social d’INCINERIS est situé
        Immeuble LE WELLICE - 50 rue de la vague, 4ème étage - 59650 Villeneuve
        d’Ascq. Son numéro de TVA intra-communautaire est FR68 805 018 959.
        Courriel : contact@esthima.fr, n° de téléphone : 0 805 622 222 (service
        client, appel non surtaxé). <br />- Le Client, personne physique
        majeure.
      </p>
      <p>
        1. L’ACTIVITÉ DU PRESTATAIRE ET CHAMPS D’APPLICATION DES CGV <br /> Le
        Prestataire propose des services funéraires et de crémation pour animaux
        de compagnie. Cette activité est exercée sous la marque « Esthima ». Les
        CGV s’appliquent également aux ventes d’accessoires tels que des urnes
        funéraires notamment. Les CGV s’appliquent uniquement au conventions
        conclues chez les partenaires Esthima. Le partenaire est indépendant du
        Prestataire. Le Partenaire n’est pas une partie au contrat entre le
        Prestataire et le Client.
      </p>
      <p>
        2. L’ANIMAL DE COMPAGNIE <br />
        Le Client reconnaît : <br />
        - qu’il est propriétaire ou représentant légal de l’animal de compagnie
        et est donc autorisé à acheter et faire exécuter un service un service
        funéraire et de crémation pour cet animal ; <br />- qu’il a constaté
        lui-même le décès de l’animal ou a fait constater son décès par un
        vétérinaire (prestation du vétérinaire non comprise dans le prix du
        Prestataire) ; <br />- que l’animal décédé n’a ni mordu ou griffé une
        personne dans les 15 jours précédant son décès (article R.223-35 du Code
        Rural et de la Pêche Maritime), n’est pas actuellement placé sous
        surveillance sanitaire, et qu’il n’a pas voyagé dans un pays où la rage
        est endémique dans les 6 mois précédant son décès.
      </p>
      <p>
        3. CONCLUSION DE LA CONVENTION <br />
        La convention comprend : <br />- Le détail ci-joint des prestations et
        produits achetés ainsi que l’identité et les coordonnées du Client,
        l’identité et la description de l’animal et, le cas échéant, les
        coordonnées du vétérinaire ; <br />- Les présentes CGV. <br />
        Sous réserve du droit de rétractation (si applicable), la convention
        devient défnitive lorsqu’elle est signée par le Client et le
        Prestataire.
      </p>
      <p>
        4. AVANT LA PRISE EN CHARGE DU CORPS DE L’ANIMAL <br />
        Avant que le corps de l’animal ne soit pris en charge par le
        Prestataire, le Client doit : <br />- Maintenir l’animal dans les
        meilleures conditions possibles pour préserver l’intégrité du corps,
        notamment en conservant le corps au froid. Le Client peut également
        décider de laisser le corps de l’animal chez son vétérinaire (prestation
        du vétérinaire non comprise dans le prix du Prestataire). Si le corps de
        l’animal n’a pas été conservé dans des conditions permettant sa prise en
        charge par le Prestataire, celui-ci a la possibilité de refuser tout
        service. Il s’agit d’un motif légitime de refus de vente. Dans une telle
        hypothèse, la convention sera annulée et le Client sera remboursé des
        sommes payées. <br />- Supprimer tout objet ou vêtement de l’animal
        (collier, pendentif, harnais, vêtements, gps…). Tout objet ou vêtement
        oublié ne pourra pas être récupéré après la prise en charge du corps de
        l’animal par le Prestataire.
      </p>
      <p>
        5. EXÉCUTION DE LA PRESTATION COMMANDÉE <br />
        a. Prise en charge du corps de l’animal Le corps de l’animal peut être :
        <br />- Pris en charge par le Prestataire chez le Client si ce service
        est disponible dans la zone géographique du Client. Des frais de
        déplacement seront appliqués (sur devis) <br />- Pris en charge par le
        Prestataire chez le vétérinaire si le Client le souhaite, sous réserve
        des conditions prévues à l’article « INTERVENTION DU VETERINAIRE DU
        CLIENT » ci-dessous. <br />- Déposé par le Client dans l’une les agences
        ou crématoriums Esthima
      </p>
      <p>
        b. Modalité d’exécution pour le service « crémation privé » avec service
        funéraire <br />
        Si la commande du Client intègre un service funéraire et en fonction de
        l’état de conservation de l’animal, le Prestataire se réserve le droit
        de proposer une modifcation de la prestation commandée. En cas de refus
        du Client, des frais de restitution incluant la conservation et l’examen
        du corps de l’animal seront facturés. Hors facturation de ces frais, la
        commande du Client sera annulée et les éventuels frais (autres que ceux
        relations à la conservation et l’examen du corps de l’animal) encaissés
        par le Prestataire seront remboursés.
      </p>
      <p>
        c. Délai d’exécution <br />
        Le délai entre la signature de la convention et la crémation est au
        maximum de 30 jours calendaires. Le Prestataire ne peut indiquer par
        avance la date exacte de crémation, laquelle peut donc intervenir
        n’importe quel jour entre le jour de signature du contrat et les trente
        jours qui suivent. Si la prestation commandée prévoit la restitution des
        cendres, celle-ci intervient dans un délai complémentaire maximum de 7
        jours. Certaines étapes de la prestation commandée peuvent nécessiter la
        prise d’un rendez-vous préalable (ex : collecte du corps de l’animal ou
        restitution des cendres). Le rendez-vous est déterminé par les deux
        parties. Chaque partie s’engage à honorer les rendez-vous convenus.
      </p>
      <p>
        d. Le lieu de la crémation <br />
        Le lieu de la crémation est déterminé par le Prestataire. L’adresse du
        lieu de crémation est mentionnée dans la convention. Si, après signature
        de la convention, le lieu de crémation désigné est indisponible pour des
        raisons techniques, de sécurité ou sanitaire, le lieu de crémation
        pourra être modifé par le Prestataire sans frais complémentaire à la
        charge du Client.
      </p>
      <p>
        e. Restitution des cendres <br />
        Si la prestation commandée comprend la restitution des cendres, cette
        restitution sera effectuée soit dans une urne de dispersion comprise par
        défaut dans le prix du service, soit dans l’urne décorative ou article
        funéraire acheté(e) par le Client et non comprise dans le prix de la
        prestation. La restitution des cendres peut être effectuée, au choix du
        Client, selon l’un des procédés suivants : <br />- Le Client vient
        récupérer les cendres au crématorium ou en agence désignée dans la
        convention. <br />- Le Prestataire dépose les cendres chez le
        vétérinaire du Client. Le Client se charge de récupérer les cendres chez
        le vétérinaire (prestation du vétérinaire non comprise dans le prix du
        Prestataire). <br />- Le Prestataire remet les cendres chez le Client.
        La restitution des cendres au domicile du Client est une option payante.
        Le Prestataire déconseille vivement le Client de confer la livraison à
        son domicile des cendres par un transporteur tiers. Toutefois, sur
        demande expresse du Client, le Prestataire peut remettre les cendres à
        un transporteur désigné par le Client. Dans ce cas, le Client est averti
        que le risque de perte ou d’endommagement des cendres est transféré au
        Client lors de la remise des cendres au transporteur.
      </p>
      <p>
        6. INTERVENTION DU VÉTÉRINAIRE DU CLIENT <br />
        L’exécution de la prestation peut nécessiter la collaboration du
        vétérinaire du Client. Le vétérinaire est un professionnel indépendant
        du Prestataire. L’intervention du vétérinaire n’est pas comprise dans le
        prix des services et produits commandés. Le Client doit se renseigner
        auprès du vétérinaire pour connaître le prix de ses prestations.
      </p>
      <p>
        7. PRIX ET PAIEMENT <br />
        Les prix des services et des articles funéraires sont consultables en
        crématorium, agence Esthima et sur le site esthima.fr. Les prix des
        services et produits commandés par le Client sont mentionnés sur le
        document joint. Les prix sont mentionnés TTC. Le paiement peut être
        effectué en espèces, par carte bancaire ou chèque, en euros. Le paiement
        s’effectue en intégralité à la signature de la commande.
      </p>
      <p>
        Paiement en plusieurs fois avec Alma : <br />
        Le paiement en plusieurs fois/différé est disponible via notre
        partenaire Alma. <br />
        La sécurisation des paiements est assurée par Alma et ses prestataires.
        Tous les paiements sont protégés par le 3D Secure. <br />
        Seules les commandes entre 180 € et 3 500 € sont éligibles au paiement
        avec Alma. <br />
        Des frais seront facturés en sus du montant de la transaction. Alma est
        gestionnaire de télé-paiement et délivre un certifcat électronique qui
        vaudra preuve du montant et de la date de la transaction conformément
        aux dispositions des articles 1316 et suivants du code civil. <br />
        Toute résiliation des présentes CGV entraine la résiliation des CGU
        entre Alma et le Client.
      </p>
      <p>
        8. GARANTIES SUR LES PRODUITS VENDUS <br />
        Les règles concernant les garanties s’appliquent aux ventes d’articles
        funéraires tels que des urnes funéraires. Le Client bénéfcie de la
        garantie légale de conformité (L217-3 et suivants du Code de la
        consommation) et de la garantie légale des défauts de la chose vendue
        (1641 à 1649 du Code civil) dans les conditions et limites de la loi. Le
        Prestataire n’accorde pas de garantie commerciale complémentaire.
      </p>
      <p>
        Le consommateur dispose d’un délai de deux ans à compter de la
        délivrance du bien pour obtenir la mise en œuvre de la garantie légale
        de conformité en cas d’apparition d’un défaut de conformité. Durant ce
        délai, le consommateur n’est tenu d’établir que l’existence du défaut de
        conformité et non la date d’apparition de celui-ci. <br />
        Lorsque le contrat de vente du bien prévoit la fourniture d’un contenu
        numérique ou d’un service numérique de manière continue pendant une
        durée supérieure à deux ans, la garantie légale est applicable à ce
        contenu numérique ou ce service numérique tout au long de la période de
        fourniture prévue. Durant ce délai, le consommateur n’est tenu d’établir
        que l’existence du défaut de conformité affectant le contenu numérique
        ou le service numérique et non la date d’apparition de celui-ci.
        <br />
        La garantie légale de conformité emporte obligation pour le
        professionnel, le cas échéant, de fournir toutes les mises à jour
        nécessaires au maintien de la conformité du bien.
        <br />
        La garantie légale de conformité donne au consommateur droit à la
        réparation ou au remplacement du bien dans un délai de trente jours
        suivant sa demande, sans frais et sans inconvénient majeur pour lui.
        <br />
        Si le bien est réparé dans le cadre de la garantie légale de conformité,
        le consommateur bénéfcie d’une extension de six mois de la garantie
        initiale. <br />
        Si le consommateur demande la réparation du bien, mais que le vendeur
        impose le remplacement, la garantie légale de conformité est renouvelée
        pour une période de deux ans à compter de la date de remplacement du
        bien. <br />
        Le consommateur peut obtenir une réduction du prix d’achat en conservant
        le bien ou mettre fn au contrat en se faisant rembourser intégralement
        contre restitution du bien, si : <br />
        1° Le professionnel refuse de réparer ou de remplacer le bien ; <br />
        2° La réparation ou le remplacement du bien intervient après un délai de
        trente jours ; <br />
        3° La réparation ou le remplacement du bien occasionne un inconvénient
        majeur pour le consommateur, notamment lorsque le consommateur supporte
        défnitivement les frais de reprise ou d’enlèvement du bien non conforme,
        ou s’il supporte les frais d’installation du bien réparé ou de
        remplacement ; <br />
        4° La non-conformité du bien persiste en dépit de la tentative de mise
        en conformité du vendeur restée infructueuse. <br />
        Le consommateur a également droit à une réduction du prix du bien ou à
        la résolution du contrat lorsque le défaut de conformité est si grave
        qu’il justife que la réduction du prix ou la résolution du contrat soit
        immédiate. Le consommateur n’est alors pas tenu de demander la
        réparation ou le remplacement du bien au préalable. <br />
        Le consommateur n’a pas droit à la résolution de la vente si le défaut
        de conformité est mineur <br />
        Toute période d’immobilisation du bien en vue de sa réparation ou de son
        remplacement suspend la garantie qui restait à courir jusqu’à la
        délivrance du bien remis en état.
        <br />
        Les droits mentionnés ci-dessus résultent de l’application des articles
        L217-1 à L217-32 du code de la consommation. <br />
        Le vendeur qui fait obstacle de mauvaise foi à la mise en œuvre de la
        garantie légale de conformité encourt une amende civile d’un montant
        maximal de 300 000 euros, qui peut être porté jusqu’à 10 % du chiffre
        d’affaires moyen annuel (article L241-5 du code de la consommation).{" "}
        <br />
        Le consommateur bénéfcie également de la garantie légale des vices
        cachés en application des articles 1641 à 1649 du code civil, pendant
        une durée de deux ans à compter de la découverte du défaut. <br />
        Cette garantie donne droit à une réduction de prix si le bien est
        conservé ou à un remboursement intégral contre restitution du bien.
      </p>
      <p>
        9.TRAITEMENT DES DONNÉES PERSONNELLES <br />
        Les données recueillies par le Prestataire auprès du Client font l’objet
        d’un traitement dans la limite de ce qui est nécessaire à la bonne
        exécution des services. Le responsable du traitement des données
        personnelles est la société INCINERIS. Selon le type de prestations
        commandées par le Client, l’utilisation de ces données a pour finalité
        de : <br />- s’assurer de la bonne exécution de la prestation, <br />-
        prise en charge du corps de l’animal, <br />- transporter le corps vers
        le crématorium, <br />- informer la famille sur le suivi du corps,{" "}
        <br />- organiser la cérémonie, <br />- gérer le retour des cendres de
        l’animal, <br />- traiter les demandes particulières, <br />- facturer{" "}
        Les catégories de données personnelles collectées et traitées sont :{" "}
        <br />- données d’identifcation (nom, prénom du Client ou du contact,
        nom du vétérinaire), <br />- données de contact (adresse postale du
        Client, email, téléphone, adresse de prise en charge du corps de
        l’animal), <br />- données relatives à l’animal (nom, espèce, race,
        identifcation, date du décès...) <br />
        Ces données sont traitées par les services internes du Prestataire et
        ses sous-traitants (accueil téléphonique sur site, relation clients,
        logistique, informatique, comptabilité...). Elles sont conservées
        pendant l’exécution des services commandés puis sont archivées. Les
        données collectées sont conservées au sein de l’Union Européenne. Des
        mesures techniques appropriées et adaptées au niveau de sensibilité et
        de risque sont mises en place pour éviter la perte, la suppression, la
        mauvaise utilisation ou l’altération de ces données. <br />
        Conformément aux dispositions en vigueur, le Client dispose d’un droit
        d’accès, de rectifcation, de portabilité, de suppression et d’opposition
        au traitement de ses données personnelles. Pour exercer ce droit, le
        Client peut adresser un email à donnees-personnelles@esthima.fr ou un
        courrier à INCINERIS, service « données personnelles », Immeuble LE
        WELLICE - 50 rue de la vague, 4ème étage - 59650 Villeneuve d’Ascq. Si
        le Client n’est pas satisfait de la réponse à sa demande, il peut
        également présenter une réclamation devant la Commission Nationale
        Informatique et Libertés (CNIL), 3 Place de Fontenoy, 75007 Paris.
      </p>
      <p>
        10. OPPOSITION AU DÉMARCHAGE TÉLÉPHONIQUE <br />
        Le Prestataire rappelle au Client qu’il a le droit de s’inscrire sur une
        liste d’opposition au démarchage téléphonique : www.bloctel.gouv.fr
      </p>
      <p>
        11. ASSURANCE RESPONSABILITÉ PROFESSIONNELLE <br />
        Le Prestataire a souscrit une assurance responsabilité professionnelle
        dont la couverture géographique est la France métropolitaine, les
        Départements et Collectivités d’Outre-Mer, les principautés d’Andorre et
        de Monaco. La compagnie d’assurance est GENERALI IARD.
      </p>
      <p>
        12. FORCE MAJEURE <br />
        Les Parties ne sont pas responsables si elles sont dans l’impossibilité
        d’exécuter leurs obligations ou si elles sont dans l’impossibilité de
        les exécuter en respectant les délais convenus lorsque cela est justifé
        par une situation de force majeure (article 1218 du Code civil).
      </p>
      <p>
        13. TRAITEMENT DES RÉCLAMATIONS <br />
        En cas d’insatisfaction, de question ou de réclamation concernant une
        commande, le Client peut contacter le service client du Prestataire :{" "}
        <br />- Par courrier : Immeuble LE WELLICE - 50 rue de la vague, 4ème
        étage - 59650 Villeneuve d’Ascq <br />- Par email : contact@esthima.fr{" "}
        <br />- Par téléphone : 0 805 622 222 du lundi au vendredi de 9h à 18h
        et le samedi de 9h à 13h (service et appel gratuit).
      </p>
      <p>
        14. RÉSOLUTION AMIABLE DES LITIGES <br />
        En cas de litige, le Client peut saisir un médiateur des litiges de la
        consommation pour tenter de résoudre de manière amiable un litige qui
        l’opposerait au Prestataire. <br />
        Ainsi, le Client peut saisir : <br />
        Nom du médiateur : SAS MEDIATION SOLUTION (Société MEDIATION SOLUTION
        RCS 832 869 564) <br />
        Adresse postale : 222 chemin de la bergerie 01800 Saint Jean de Niost{" "}
        <br />
        Email : contact@sasmediationsolution-conso.fr <br />
        Téléphone : 04 82 53 93 06 <br />
        Formulaire en ligne intitulé « Saisir le médiateur » sur le site
        https://www.sasmediationsolution-conso.fr <br />
        Quel que soit le moyen de saisine utilisé, la demande doit
        impérativement contenir : <br />- Les coordonnées postales,
        téléphoniques et électroniques du Client, <br />- Le nom et l’adresse et
        le numéro d’enregistrement chez Sas Médiation Solution du Prestataire,{" "}
        <br />- Un exposé succinct des faits. Le Client précisera au médiateur
        ce qu’il attend de cette médiation et pourquoi, <br />- Le Client doit
        justifer avoir tenté, au préalable, de résoudre son litige directement
        auprès du Prestataire par une réclamation écrite. <br />
        Le Client est également libre de saisir le juge compétent de son choix.
      </p>
      <p>
        15. RÉTRACTATION <br />
        Si la convention a été conclue à distance ou hors établissement, le
        Client dispose d’un délai de 14 jours à compter du jour de la signature
        de la convention pour exercer son droit de rétraction. Pour exercer son
        droit de rétractation, le Client doit, avant l’expiration du délai prévu
        ci-dessus, informer le Prestataire par une déclaration, dénuée
        d’ambiguïté, exprimant sa volonté de se rétracter. Le client peut
        également utiliser le modèle proposé sur le site de l’Institut national
        de la consommation https://www.inc-conso.fr. Cette information devra
        être notifée à l’Entreprise à l’adresse suivante : contact@esthima.fr.{" "}
        <br />
        Exception au droit de rétractation : Conformément aux dispositions de
        l’article L221-28 1) du Code de la consommation, le droit de
        rétractation ne peut être exercé pour les contrats de fourniture de
        services pleinement exécutés avant la fn du délai de rétractation et
        dont l’exécution a commencé après accord préalable exprès du
        consommateur et renoncement exprès à son droit de rétractation et 3)
        pour la fourniture de biens confectionnés selon les spécifcations du
        consommateur ou nettement personnalisés. Compte tenu de la spécifcité
        des services fournis par le Prestataire impliquant une transformation
        irréversible de la dépouille de l’animal confé, et de la réglementation
        sanitaire en vigueur, et notamment de la nécessité de maintenir les
        dépouilles d’animaux dans des conditions de réfrigération, il est
        demandé au Client, à titre de conditions particulière, de consentir au
        commencement de l’exécution des prestations avant l’expiration du délai
        de rétractation et, par voie de conséquence, de renoncer expressément à
        l’exercice de ce droit. Cette renonciation vaut également pour les
        commandes d’articles funéraires personnalisés. <br />
        Conséquence de l’exercice du droit de rétraction : Sauf s’il a
        expressément renoncé à son droit de rétractation dans les conditions tel
        qu’indiquées ci-avant, si le Client exerce son droit de rétractation
        dans les formes et délais prévus, la convention sera résiliée, le
        Prestataire ne sera tenu par aucun engagement. Le Prestataire informera
        par tout moyen approprié le Client du lieu où celui-ci pourra récupérer
        la dépouille de l’animal. Le Client s’engage à récupérer sans délai le
        corps de l’animal. Le Prestataire remboursera le paiement reçu par le
        Client sans retard excessif dans les 14 jours suivant la réception d’une
        déclaration de rétractation et au plus tard au jour de la récupération
        par le Client du corps de l’animal et de la restitution des articles
        funéraires, déduction faite des éventuels coûts de renvoi des biens, à
        la charge du Client. Compte tenu de la spécifcité des services fournis
        par le Prestataire impliquant une transformation irréversible de la
        dépouille de l’animal confié, et de la réglementation sanitaire en
        vigueur, et notamment de la nécessité de maintenir les dépouilles
        d’animaux dans des conditions de réfrigération, il est demandé au
        Client, à titre de conditions particulière, de consentir au commencement
        de l’exécution des prestations avant l’expiration du délai de
        rétractation et, par voie de conséquence, de renoncer expressément à
        l’exercice de ce droit. Cette renonciation vaut également pour les
        commandes d’articles funéraires personnalisés. <br />
        Conséquence de l’exercice du droit de rétraction : Sauf s’il a
        expressément renoncé à son droit de rétractation dans les conditions tel
        qu’indiquées ci-avant, si le Client exerce son droit de rétractation
        dans les formes et délais prévus, la convention sera résiliée, le
        Prestataire ne sera tenu par aucun engagement. Le Prestataire informera
        par tout moyen approprié le Client du lieu où celui-ci pourra récupérer
        la dépouille de l’animal. Le Client s’engage à récupérer sans délai le
        corps de l’animal. Le Prestataire remboursera le paiement reçu par le
        Client sans retard excessif dans les 14 jours suivant la réception d’une
        déclaration de rétractation et au plus tard au jour de la récupération
        par le Client du corps de l’animal et de la restitution des articles
        funéraires, déduction faite des éventuels coûts de renvoi des biens, à
        la charge du Client.
      </p>
      <p>
        MODÈLE DE FORMULAIRE DE RÉTRACTATION <br />
        Formulaire à envoyer à Immeuble LE WELLICE - 50 rue de la vague, 4ème
        étage - 59650 Villeneuve d’Ascq ou contact@esthima.fr <br />
        Je vous notifie par la présente ma rétractation de la commande : <br />
        Convention n° <br />
        Commandé le : <br />
        Nom du Client : <br />
        Date : <br />
        Signature du Client (uniquement en cas d’envoi du présent formulaire sur
        papier)
      </p>
    </div>
  );
}
