import React from "react";
import { useParams } from "react-router-dom";
import { Button, Flex } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";

export function Unsubscribes() {
  const { sClinicReference, sConventionId } = useParams();

  return (
    <div className={"content"}>
      <Flex gap="small" wrap="wrap">
        <Button
          size={"large"}
          type="primary"
          icon={<ArrowRightOutlined />}
          style={{
            background: "#d53865",
          }}
          href={
            process.env.REACT_APP_BACKEND_ENDPOINT +
            "/clinics/" +
            sClinicReference +
            "/conventions/" +
            sConventionId +
            "/stops"
          }
          className="custom-button"
        >
          Cliquez ici pour vous désinscrire
        </Button>
      </Flex>
    </div>
  );
}
//${sConventionId}
