import React from "react";
import { Button, Result } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { translate } from "../../../services/i18n/i18n";

export function Failures() {
  return (
    <div className={"content"}>
      <Result
        status="error"
        title={translate("failures.first_text")}
        subTitle={translate("failures.second_text")}
        extra={[
          <Button size={"large"} className="custom-button" href="/">
            {translate("failures.third_text")}
          </Button>,
          <Button
            size={"large"}
            type="primary"
            htmlType="submit"
            icon={<SearchOutlined />}
            style={{ background: "#d53865" }}
            className="custom-button"
          >
            {translate("failures.fourth_text")}
          </Button>,
        ]}
      />
    </div>
  );
}
