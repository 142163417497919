import React from "react";
import { Button, Result } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { translate } from "../../../services/i18n/i18n";

export function Pending() {
  return (
    <div className={"content"}>
      <Result
        title={translate("pending.first_text")}
        subTitle={translate("pending.second_text")}
        extra={
          <Button
            size={"large"}
            type="primary"
            icon={<ArrowRightOutlined />}
            style={{
              background: "#d53865",
            }}
            href="https://www.incineris.fr"
            className="custom-button"
          >
            {translate("pending.third_text")}
          </Button>
        }
      />
    </div>
  );
}
