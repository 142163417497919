import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ConventionsService } from "../../../services/conventions/conventions.service";
import { Convention } from "../../../domains/convention";
import { Button, Col, Row, Card, Skeleton, Flex, Result } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { translate } from "../../../services/i18n/i18n";

interface RouteParams {
  [key: string]: string | undefined;
}

export function Summaries() {
  const [convention, setConvention] = useState<Convention>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { number: routeNumber } = useParams<RouteParams>();
  const number = routeNumber;
  const [error, setError] = useState<string>("");

  async function onFinish() {
    try {
      setIsSubmitting(true);
      const conventionsService = new ConventionsService();
      const result = await conventionsService.getConvention(number);

      if ("error" in result) {
        setError(result.error);
      } else if (result) {
        setConvention(result.convention);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  useEffect(() => {
    onFinish();
  }, []);

  return (
    <div className="content">
      {isSubmitting ? (
        <Skeleton active />
      ) : (
        <div>
          {error && (
            <Result
              status="error"
              title={translate(error)}
              extra={[
                <Button size="large" className="custom-button" href="/">
                  {translate("summaries.first_text")}
                </Button>,
              ]}
            />
          )}

          {convention && (
            <>
              <Row gutter={[16, 16]}>
                <Col xs={24} md={14} lg={14}>
                  <Card
                    title={translate("summaries.second_text")}
                    bordered={false}
                    style={{ background: "#f8efea" }}
                  >
                    <p className="custom-content">
                      {translate("summaries.third_text")}
                    </p>

                    <p className="custom-content">
                      {translate("summaries.fourth_text")} {convention.incCode}
                    </p>
                    <p className="custom-content">
                      {translate("summaries.fifth_text")}{" "}
                      {convention.animalName}
                    </p>
                  </Card>
                </Col>
                <Col xs={24} md={10} lg={10}>
                  <Card
                    title={translate("summaries.sixth_text")}
                    bordered={false}
                    style={{ background: "#f8efea" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: 10,
                      }}
                    >
                      <span>{convention.offerName}</span>
                      <span>{convention.offerPrice} €</span>
                    </div>
                    {convention.products.map((product, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: 5,
                        }}
                      >
                        <span>{product.productName}</span>
                        <span>
                          {product.productQuantity * product.productPrice} €
                        </span>
                      </div>
                    ))}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: 10,
                        borderTop: "1px solid #ccc",
                        paddingTop: 5,
                      }}
                    >
                      <span>
                        <strong>{translate("summaries.seventh_text")}</strong>
                      </span>
                      <span>{convention.total} €</span>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Flex gap="small" wrap="wrap" style={{ marginTop: 20 }}>
                <Button size="large" className="custom-button" href="/">
                  {translate("summaries.eighth_text")}
                </Button>
                <Button
                  size="large"
                  type="primary"
                  icon={<ArrowRightOutlined />}
                  style={{ background: "#d53865" }}
                  href={"/payments/" + convention.incCode}
                  className="custom-button"
                >
                  {translate("summaries.ninth_text")}
                </Button>
              </Flex>
            </>
          )}
        </div>
      )}
    </div>
  );
}
