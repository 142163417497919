import React from "react";
import { Button, Form, Input, FormProps } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { translate } from "../../../services/i18n/i18n";

type FieldType = {
  incCode?: string;
};

export function Searchs() {
  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    window.location.href = "/" + values.incCode;
  };

  const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (
    errorInfo,
  ) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className={"content"}>
      <p className={"custom-content"}>{translate("searchs.first_text")}</p>
      <div style={{ marginTop: 15 }}>
        <Form
          layout="inline"
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item<FieldType>
            name="incCode"
            rules={[
              { required: true, message: translate("searchs.second_text") },
            ]}
          >
            <Input
              size="large"
              placeholder="ex. INC87654321"
              style={{ background: "#f8efea" }}
            />
          </Form.Item>

          <Form.Item>
            <Button
              size={"large"}
              type="primary"
              htmlType="submit"
              icon={<SearchOutlined />}
              style={{ background: "#d53865" }}
              className={"custom-button"}
            >
              {translate("searchs.third_text")}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
