import {
  GetConventionResponse,
  GetConventionSuccess,
  PostPaymentResponse,
  PostPaymentSuccess,
  Product,
} from "./conventions.interface";
import { getStatusMessage, StatusMessageType } from "../../domains/error";
import { Convention, GetConventionResult } from "../../domains/convention";
import { PostPaymentResult } from "../../domains/payment";

function isResponseOKGetConvention(
  response: Response,
  json: GetConventionResponse,
): json is GetConventionSuccess {
  return response.ok;
}

function isResponseOKPostPayment(
  response: Response,
  json: PostPaymentResponse,
): json is PostPaymentSuccess {
  return response.ok;
}

export class ConventionsService {
  async getConvention(name: string | undefined): Promise<GetConventionResult> {
    const options: object = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response: Response = await fetch(
      process.env.REACT_APP_BACKEND_ENDPOINT + "/searchs/" + name,
      options,
    );
    const json: GetConventionResponse = await response.json();
    let statusMessageType: StatusMessageType = getStatusMessage(
      response.status,
    );

    if (!isResponseOKGetConvention(response, json)) {
      return {
        statusMessageType: statusMessageType,
        error: json.error,
        informations: json.informations.informations,
      };
    }

    const convention: Convention = {
      incCode: json.incCode,
      animalName: json.animalName,
      offerName: json.offerName,
      offerPrice: json.offerPrice,
      total: json.total,
      products: json.products.map((product: Product) => ({
        productName: product.productName,
        productPrice: product.productPrice,
        productQuantity: product.productQuantity,
        productReference: product.productReference,
        productReturnMode: product.productReturnMode,
      })),
    };

    return {
      statusMessageType: statusMessageType,
      convention: convention,
    };
  }

  async postPayment(
    name: string | undefined,
    paymentMethod: string,
  ): Promise<PostPaymentResult> {
    const options: object = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ paymentMethod: paymentMethod }),
    };

    const response: Response = await fetch(
      process.env.REACT_APP_BACKEND_ENDPOINT + "/payments/" + name,
      options,
    );
    const json: PostPaymentResponse = await response.json();
    let statusMessageType: StatusMessageType = getStatusMessage(
      response.status,
    );

    if (!isResponseOKPostPayment(response, json)) {
      return {
        statusMessageType: statusMessageType,
        error: json.error,
        informations: json.informations.informations,
      };
    }

    return {
      statusMessageType: statusMessageType,
      url: json.url,
    };
  }
}
