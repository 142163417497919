import React from "react";
import { Layout, theme } from "antd";
import { Summaries } from "./pages/conventions/summaries/summaries";
import { Link, Route, Routes } from "react-router-dom";
import { Searchs } from "./pages/conventions/searchs/searchs";
import { Confirmations } from "./pages/conventions/status/confirmations";
import { Cancels } from "./pages/conventions/status/cancels";
import { Pending } from "./pages/conventions/status/pending";
import { Failures } from "./pages/conventions/status/failures";
import { Terms } from "./pages/conventions/landing/terms";
import { translate } from "./services/i18n/i18n";
import { Payments } from "./pages/conventions/payments/payments";
import { Unsubscribes } from "./pages/conventions/landing/unsubscribes";

const { Header, Content, Footer, Sider } = Layout;

export function App() {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <Layout>
      <Layout>
        <Header className={"header"}>
          <Link to="/">
            <img
              src="https://media.cie-veterinaires.fr/images/logos/logo_esthima.png"
              alt="Esthima Logo"
              style={{ height: 100 }}
            />
          </Link>
        </Header>

        <Content
          style={{
            minHeight: "calc(100vh - 160px - 124px)",
            background: "#fff",
          }}
        >
          <div
            style={{
              background: "#fff",
            }}
          >
            <Routes>
              <Route path="/" element={<Searchs />} />
              <Route path="/:number" element={<Summaries />} />
              <Route path="/confirmations" element={<Confirmations />} />
              <Route path="/cancels" element={<Cancels />} />
              <Route path="/pending" element={<Pending />} />
              <Route path="/failures" element={<Failures />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/payments" element={<Payments />} />
              <Route
                path="/unsubscribes/:sClinicReference/:sConventionId"
                element={<Unsubscribes />}
              />
              <Route path="/payments/:number" element={<Payments />} />
            </Routes>
          </div>
        </Content>
        <Footer>
          <Link to={"/terms"} target={"_blank"} className={"custom-a"}>
            {translate("footer.first_text")}
          </Link>
          |{" "}
          <Link
            to={"https://www.esthima.fr/cgu"}
            target={"_blank"}
            className={"custom-a"}
          >
            {translate("footer.second_text")}
          </Link>
          |{" "}
          <Link
            to={"https://www.esthima.fr/mentions-legales"}
            target={"_blank"}
            className={"custom-a"}
          >
            {translate("footer.third_text")}
          </Link>
          |{" "}
          <Link
            to={"https://www.esthima.fr/politique-confidentialite"}
            target={"_blank"}
            className={"custom-a"}
          >
            {translate("footer.fourth_text")}
          </Link>
        </Footer>
      </Layout>
    </Layout>
  );
}

export default App;
