export interface Error {
  statusMessageType: StatusMessageType;
  error: string;
  informations?: string;
}

export enum StatusMessageType {
  SUCCESS = "success",
  AUTH_FAILURE = "auth_failure",
  ERROR = "error",
  WARNING = "warning",
}

export function getStatusMessage(status: number): StatusMessageType {
  switch (status) {
    case 200:
    case 201:
    case 204:
      return StatusMessageType.SUCCESS;
    case 400:
    case 403:
    case 404:
    case 500:
      return StatusMessageType.ERROR;
    case 401:
      return StatusMessageType.AUTH_FAILURE;
    default:
      return StatusMessageType.WARNING;
  }
}
